import { PayloadAction } from '@reduxjs/toolkit';
import { CoreApiErrorType } from 'types/CoreApiErrorType';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
	ContainerState, Login, User
} from './types';


// The initial state of the ReportsPage container
export const initialState: ContainerState = {
	loading: false,
    result: '',
    user: (
        (sessionStorage.user && JSON.parse(sessionStorage.user)) ||
        (localStorage.user && JSON.parse(localStorage.user))
    ),
    merchant: (
        (sessionStorage.merchant && JSON.parse(sessionStorage.merchant)) ||
        (localStorage.merchant && JSON.parse(localStorage.merchant))
    )
};


const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
		login(state, action: PayloadAction<Login>) {
			state.user = undefined;
			state.loading = true;
			state.apiError = undefined;
		},
		logout(state) {
			state.user = undefined;
			state.loading = false;
			state.apiError = undefined;
            localStorage.removeItem('user')
            sessionStorage.removeItem('user')
		},
        merchantLogout(state) {
			state.merchant = undefined;
			state.loading = false;
			state.apiError = undefined;
            localStorage.removeItem('merchant')
            sessionStorage.removeItem('merchant')
        },
		successfulMerchantLogin(state, action: PayloadAction<User>) {
			state.merchant = action.payload;
			state.loading = false;
			state.apiError = undefined;

            if (localStorage.getItem('rememberMe') === 'true') {
                localStorage.setItem('merchant', JSON.stringify(action.payload))
            } else {
                sessionStorage.setItem('merchant', JSON.stringify(action.payload))
            }
		},
		successfulMarketplaceLogin(state, action: PayloadAction<User>) {
			state.user = action.payload;
			state.loading = false;
			state.apiError = undefined;

            if (localStorage.getItem('rememberMe') === 'true') {
                localStorage.setItem('user', JSON.stringify(action.payload))
            } else {
                sessionStorage.setItem('user', JSON.stringify(action.payload))
            }
		},

        apiError(state, action: PayloadAction<{result: string, apiError: CoreApiErrorType}>) {
			state.user = undefined;
            state.loading = false;
            state.result = action.payload.result;
            state.apiError = action.payload.apiError
        },
	}
});
	
export const {
	actions: userActions,
	reducer,
	name: sliceKey,
} = userSlice;