import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { CoreApiErrorType } from 'types/CoreApiErrorType';

import { RootState } from 'types/RootState';
import { request } from 'utils/request';
import { merchantPageActions } from './slice';

import { PayloadAction } from '@reduxjs/toolkit';
import { userActions } from 'app/common/auth/slice';
import {
    GENERATE_QR,
    GROUPS_URL,
    GROUP_URL,
    ITEMS_URL,
    ITEM_URL,
    SAVE_OPERATION_HOURS
} from 'app/common/core_api/resources';
import { Group, Item, WeeklyOperationHours } from 'types';

import Swal from 'sweetalert2';

export function* getGroups() {
    yield delay(1000);
  
    try {
        let getMerchantId = (state: RootState) => state?.merchantPage?.merchant_id;
        let merchant_id: string = yield select(getMerchantId);

        let url = GROUPS_URL.replace(':merchant_id', merchant_id);

        const data = yield call(request, url);
        for (const item of data) {
            item.itemsLoading = true;
        }
        yield put(merchantPageActions.groupsLoaded(data));

        for (const key in data) {    
            yield getItems(Number.parseInt(key))
        }
    } catch (err) {
        if (err) {
            yield put(
                merchantPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* getItems(group_index: number) {
    yield delay(1000);
    
    try {
        let getGroups = (state: RootState) => state?.merchantPage?.groups;
        let groups: Array<Group> = yield select(getGroups);

        let group_id = (groups[group_index].id || '').toString();
        let url = ITEMS_URL.replace(':group_id', group_id);

        const data = yield call(request, url);
        yield put(merchantPageActions.itemsLoaded({
            group_index: group_index,
            items: data
        }));
    } catch (err) {
        if (err) {
            yield put(
                merchantPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* saveGroup(action: PayloadAction<{
    index?: number,
    group?: Group
}>) {
    yield delay(1000);
    var {index, group} = action.payload
    try {
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...group})
        }
        let url = GROUP_URL + (group?.id && `/${group?.id}` || '');
        group = (yield call(request, url, options))[0];
        yield put(merchantPageActions.savedGroup({index, group}));
    } catch (err) {
        if (err) {
            yield put(
                merchantPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* deleteGroup(action: PayloadAction<{index: number, id: number}>) {
    yield delay(1000);
    const {id} = action.payload
    try {
        let options: RequestInit = {
            method: "DELETE",
        }
        let url = GROUP_URL + (id && `/${id}` || '');
        yield call(request, url, options);
        yield put(merchantPageActions.deletedGroup(action.payload));
    } catch (err) {
        if (err) {
            yield put(
                merchantPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* saveItem(action: PayloadAction<{
    groupIndex: number,
    index?: number,
    item: Item
}>) {
    yield delay(2000);
    var {groupIndex, index, item} = {...action.payload}
    try {
        let getUsername = (state: RootState) => state?.user?.merchant?.username;
        let username: string = yield select(getUsername);

        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...item, username})
        }
        let url = item.id? ITEM_URL.replace(':id', `${item.id}`): ITEM_URL.replace('/:id', '')
        item = (yield call(request, url, options))[0];
        yield put(merchantPageActions.savedItem({groupIndex, index, item}));
    } catch (err) {
        if (err) {
            yield put(
                merchantPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* deleteItem(action: PayloadAction<{groupIndex: number, index: number, id: number}>) {
    yield delay(1000);
    const {groupIndex, index, id} = action.payload
    try {
        let options: RequestInit = {
            method: "DELETE",
        }
        let url = ITEM_URL.replace(':id', (id || '').toString());
        yield call(request, url, options);
        yield put(merchantPageActions.deletedItem({groupIndex, index}));
    } catch (err) {
        if (err) {
            yield put(
                merchantPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* generate(action: PayloadAction<{id: number}>) {
    yield delay(1000);
    var id = action.payload

    try {
        let options: RequestInit = {
            method: "POST",
        }
        let url = GENERATE_QR.replace(':id', (id.id).toString());
        
        const qrCode = yield call(request, url, options);;
        yield put(merchantPageActions.generated(qrCode[0]));
        Swal.fire('Success', 'Your QR is now updated', 'success')
    } catch (err) {
        let trigger = Math.random()
        if (err) {
            yield put(
                merchantPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
            Swal.fire('An error occured', '', 'error')
        }
    }
}

export function* saveOperationHours(action: PayloadAction<{username: string, operation_hours: WeeklyOperationHours}>) {
    yield delay(2000);
    var {username, operation_hours} = {...action.payload}
    try {
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(operation_hours)
        }
        let url  = SAVE_OPERATION_HOURS.replace(':username', username);
        let user = yield call(request, url, options);
        yield put(userActions.successfulMerchantLogin(user));
    } catch (err) {
        if (err) {
            yield put(
                merchantPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* merchantPageSaga() {
    yield takeLatest(merchantPageActions.getGroups.type, getGroups)
    yield takeLatest(merchantPageActions.saveGroup.type, saveGroup)
    yield takeLatest(merchantPageActions.deleteGroup.type, deleteGroup)
    yield takeLatest(merchantPageActions.saveItem.type, saveItem)
    yield takeLatest(merchantPageActions.deleteItem.type, deleteItem)
    yield takeEvery(merchantPageActions.setAvailable.type, saveItem)
    yield takeLatest(merchantPageActions.generate.type, generate)
    yield takeLatest(merchantPageActions.saveOperationHours.type, saveOperationHours)
}