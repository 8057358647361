import { memo, useEffect, useState } from "react";

import success_icon from 'assets/images/success.svg';
import error_icon   from 'assets/images/error.svg';
import loader       from 'assets/images/loader.gif';

import 'styles/order_placed.scss';
import { CONFIRM_ORDER } from "app/common/core_api/resources";
import { useQuery } from "utils/useQuery";

const ConfirmOrder = memo(() => {
    const defaultDetails = {
        customer_name  : '',
        customer_number: '',
        orders: [],
        notes: "",
        confirmed: false
    }

    const query                     = useQuery();
    const [success, setSuccess]     = useState<boolean>(false);
    const [sending, setSending]     = useState(true);
    const [{
        customer_name,
        customer_number,
        orders, notes, confirmed
    }, setDetails] = useState<{
        customer_name: string
        customer_number: string
        confirmed: boolean
        orders: {
            name    : string
            price   : number
            quantity: number
        }[]
        notes: string
    }>(defaultDetails);
    useEffect(() => {
        fetch(CONFIRM_ORDER.replace(':token', query.get('token') || ''))
        .then(resp => resp.json())
        .then(data => {
            if (data == 'does not exist') {
                setSending(false)
            }
            else {
                setDetails(data)
                setSending(false)
                setSuccess(true)
            }
        })
        .catch(err => {
            setSending(false)
            console.log(err)
        })
    }, [])

    return (
        !sending && success?
        <div className="ordering text-center py-5 px-4 d-block vh-100">
            {!confirmed && <img src={success_icon} width="100px"/>}
            <div className="mt-3 mb-5">
                <h4 className={`fw-bold text-${confirmed? 'info' : 'success'}`}>
                    ORDER {confirmed? 'ALREADY ': ''}CONFIRMED
                </h4>
                <h5 className="text-secondary">For {customer_name} ({customer_number})</h5>
            </div>
            <div style={{backgroundColor: 'inherit'}} className="order-placed mx-auto">
                <div className="orders text-start p-3 w-100" style={{border: '4px 0', height: 'fit-content', maxWidth: '500px'}}>
                    <p className="mb-0">Orders:</p>
                    {orders.map(item => 
                        <div className="d-flex justify-content-between mb-0" >
                            <span>{item.name} x {item.quantity}</span>
                            <span className="fw-bold">{(item.price*item.quantity).toLocaleString('en-US', {minimumFractionDigits: 2})} PHP</span>
                        </div>
                    )}
                </div>
                <div className="text-start p-3 w-100" style={{maxWidth: '500px'}}>
                    <p className="mb-0">Notes:</p>
                    <p className="mb-0" style={{whiteSpace: 'pre-wrap'}}>{notes}</p>
                </div>
            </div>
        </div>:
        !sending && !success?
        <div className="text-center p-5 d-block vh-100">
            <img src={error_icon} width="100px"/>
            <h2 className="fw-bold p-sm-5 mx-auto">Failed to Confirm Order</h2>
        </div>:
        <div className="text-center p-5 d-block vh-100">
            <img src={loader} width="100px"/>
            <h2 className="fw-bold p-sm-5 mx-auto">Sending Confirmation</h2>
        </div>
    )
})

export default ConfirmOrder;