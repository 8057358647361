import { createSelector } from '@reduxjs/toolkit';

import { UserState } from 'redux-oidc';
import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.user || initialState;

export const selectUser = createSelector(
    [selectDomain],
    userState => userState,
);

// KEY CLOAK
export type ContainerState = UserState;
export const initialAdminState: ContainerState = {
  user: undefined,
  isLoadingUser: false,
};

const selectAdminDomain = (state: RootState) => state?.oidc || initialAdminState;

export const selectLoadingAdmin = createSelector(
  [selectAdminDomain],
  adminState => adminState.isLoadingUser,
);

export const selectAdmin = createSelector(
  [selectAdminDomain],
  adminState => adminState.user,
);

export const kc_selectAccessToken = createSelector(
  [selectAdminDomain],
  adminState => adminState.user?.access_token,
);
// KEY CLOAK