import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { CoreApiErrorType } from 'types/CoreApiErrorType';

import Swal from 'sweetalert2';
import { RootState } from 'types/RootState';
import { request } from 'utils/request';
import { adminPageActions } from './slice';

import { ADMIN_UPLOAD_QR, CONTACTLESS_API_URL } from 'app/common/core_api/resources';

import { PayloadAction } from '@reduxjs/toolkit';
import {
    ADD_USER_URL,
    ADMIN_GENERATE_QR,
    DELETE_USER_URL, SAVE_USER_URL,
    SEARCH_USERS_URL,
    USERS_URL
} from 'app/common/core_api/resources';
import { User } from './types';

export function* getUsers() {
    yield delay(1000);
  
    try {
        let url = USERS_URL;

        const data = yield call(request, url);
        for (const item of data) {
            item.itemsLoading = true;
        }
        yield put(adminPageActions.usersLoaded(data));
    } catch (err) {
        if (err) {
            yield put(
                adminPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* searchUsers() {
    yield delay(1000);
  
    try {
        let getUserId = (state: RootState) => state?.adminPage?.keyword;
        let id: string = yield select(getUserId);

        let url = SEARCH_USERS_URL.replace(':keyword', id);

        const data = yield call(request, url);
        for (const item of data) {
            item.storesLoading = true;
        }
        
        yield put(adminPageActions.usersLoaded(data));
    } catch (err) {
        if (err) {
            yield put(
                adminPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}


export function* addUser(action: PayloadAction<{
    user?: User
}>) {
    yield delay(1000);
    var user = action.payload
    let trigger = Math.random()
    try {
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...user})
        }
        let url = ADD_USER_URL;
        user = (yield call(request, url, options))[0];
        if (user=='already_exist'){
            yield put(adminPageActions.validationError(trigger));
            Swal.fire('Username already exist', '', 'error')
        }else{
            yield put(adminPageActions.addedUser(trigger));
            Swal.fire('User added successfully', '', 'success')
        }
        
    } catch (err) {
        if (err) {
            yield put(
                adminPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
            Swal.fire('An error occured', '', 'error')
        }
    }
}

export function* saveUser(action: PayloadAction<{
    user?: User
}>) {
    yield delay(1000);
    let trigger = Math.random()
    var user = action.payload
    try {
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...user})
        }
        let url = SAVE_USER_URL;
        user = (yield call(request, url, options))[0];
        if (user=='already_exist'){
            yield put(adminPageActions.validationError(trigger));
            Swal.fire('Username already exist', '', 'error')
        }else{
            yield put(adminPageActions.savedUser(trigger));
            Swal.fire('Changes successfully saved', '', 'success')
        }
    } catch (err) {
        if (err) {
            yield put(
                adminPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
            Swal.fire('An error occured', '', 'error')
        }
    }
}

export function* deleteUser(action: PayloadAction<{id: number}>) {
    yield delay(1000);
    var id = action.payload
    let trigger = Math.random()
    try {
        let options: RequestInit = {
            method: "DELETE",
        }
        let url = DELETE_USER_URL.replace(':id', (id.id || '').toString());
        
        yield call(request, url, options);
        yield put(adminPageActions.deletedUser(trigger));
        Swal.fire('User removed successfully', '', 'success')
    } catch (err) {
        if (err) {
            yield put(
                adminPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
            Swal.fire('An error occured', '', 'error')
        }
    }
}

export function* generate(action: PayloadAction<{username: string}>) {
    yield delay(1000);
    var username = action.payload

    try {
        let options: RequestInit = {
            method: "POST",
        }
        let url = ADMIN_GENERATE_QR.replace(':username', (username.username).toString());
        
        const qrCode = yield call(request, url, options);;
        
        if(qrCode=='not_exist'){
            let rand = Math.random()
            Swal.fire('Username ' + username.username + ' does not exist', '', 'error')
            yield put(adminPageActions.generated(rand));
        }else{
            const qrDownloadUrl = `${CONTACTLESS_API_URL}/admin/qr/merchant/${username.username}`;
            Swal.fire({
                title: "Do you wish to download " + username.username + "'s QR Code?",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: `Yes`,
                cancelButtonText: `No`,
            }).then((result) => {
                if (result.isConfirmed) {
                    //window.location.href = qrDownloadUrl
                    window.open(qrDownloadUrl, '_blank');
                } else if (result.isDenied) {
                    
                }
            })
            //Swal.fire('Success', 'QR generated for username ' + username.username, 'success')
            yield put(adminPageActions.generated(qrCode[0]));
        }
    } catch (err) {
        
        if (err) {
            yield put(
                adminPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
            Swal.fire('An error occured', '', 'error')
        }
    }
}

export function* uploadQR(action: PayloadAction<{username: string, image: any}>) {
    yield delay(1000);
    var {image, username} = action.payload

    try {
        let options: RequestInit = {
            headers: {'Content-Type': 'application/json'},
            method: "POST",
            body: JSON.stringify({image})
        }
        let url = ADMIN_UPLOAD_QR.replace(':username', (username).toString());
        
        const qrCode = yield call(request, url, options);
        
        if(qrCode=='not_exist'){
            let rand = Math.random()
            Swal.fire('Username ' + username + ' does not exist', '', 'error')
            yield put(adminPageActions.generated(rand));
        }else{
            const qrDownloadUrl = `${CONTACTLESS_API_URL}/admin/qr/merchant/${username}`;
            Swal.fire({
                title: "Do you wish to download " + username + "'s QR Code?",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: `Yes`,
                cancelButtonText: `No`,
            }).then((result) => {
                if (result.isConfirmed) {
                    //window.location.href = qrDownloadUrl
                    window.open(qrDownloadUrl, '_blank');
                } else if (result.isDenied) {
                    
                }
            })
            //Swal.fire('Success', 'QR generated for username ' + username, 'success')
        }
    } catch (err) {
        
        if (err) {
            yield put(
                adminPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
            Swal.fire('An error occured', '', 'error')
        }
    }
    yield put(adminPageActions.uploadedQR());
}


export function* adminPageSaga() {
    yield takeLatest(adminPageActions.searchUsers.type, searchUsers)
    yield takeLatest(adminPageActions.getUsers.type, getUsers)
    yield takeLatest(adminPageActions.addUser.type, addUser)
    yield takeLatest(adminPageActions.saveUser.type, saveUser)
    yield takeLatest(adminPageActions.deleteUser.type, deleteUser)
    yield takeLatest(adminPageActions.generate.type, generate)
    yield takeLatest(adminPageActions.uploadQR.type, uploadQR)
}