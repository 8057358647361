import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import useCountry from "utils/country";
import { selectOrderingPage } from "../selectors";
import { orderingPageActions } from "../slice";
import MobileNumber from './MobileNumber';

const classes = {
    contacts   : "contacts row justify-content-center g-0",
    form       : "col-sm-12 col-md-10 col-lg-6 col-xl-4 p-5",
    instruction: "text-success fw-bold text-center",
}

interface Props {
    fromMarketplace: boolean
    fromQR: boolean
}

const Contacts = ({fromMarketplace, fromQR}: Props) => {
    const countries = useCountry();
    const dispatch = useDispatch();
    const orderingPage = useSelector(selectOrderingPage);
    const [contactDetails, setContactDetails] = useState(orderingPage.contacts);

    var total = 0;
    for (const item of orderingPage.cart) {
        total += item.quantity*item.item.price;
    }
    if (fromMarketplace) {
        total += orderingPage.dispatcherFee || 0;
    }
    if (contactDetails.mobile_number?.country != 'PH') {
        // total += orderingPage.smsFee || 0;
    }

    const updateContacts = (e, index) => {
        if (index === "mobile_number" && isNaN(e.target.value)) {
            e.preventDefault();
            return;
        }
        let value, mobile_number = {...contactDetails.mobile_number};

        switch (index) {
            case 'prefix':
                mobile_number.country = e.code;
                mobile_number.prefix = e.mobile_code;
                index = 'mobile_number';
                value = mobile_number;
            break;
            case 'mobile_number':
                mobile_number.number = e.target.value;
                value = mobile_number;
            break;
            default:
                value = e.target.value;
        }
        let contacts = {...contactDetails, [index]: value};
        setContactDetails(contacts);
        dispatch(orderingPageActions.setContactDetails(contacts));
    }
    if (orderingPage.cart.length == 0) {
        return <Redirect to={`/${orderingPage.merchant.username}`} />
    }
    return(
        <div className={classes.contacts}>
            <div className={classes.form}>
                {orderingPage.payLater ?
                    <h4 className="fw-bold text-secondary text-center mb-4">Enter your contact details to receive instruction on how to complete this transaction.</h4> : <>
                    <div className="text-center mb-5">
                        <h2 className="fw-bold text-success mb-0">COMPLETE YOUR TRANSACTION</h2>
                        <h5 className="text-secondary mb-0">Total amount to pay</h5>
                        <h5 className="fw-bold text-dark">₱ {total.toLocaleString('en-US', {minimumFractionDigits: 2})}</h5>
                        {fromMarketplace && fromQR && <p><small>additional fee of {orderingPage.dispatcherFee} PHP for<br/>delivering to your table</small></p>}
                        {/* {contactDetails.mobile_number.country != 'PH' && <p><small>additional fee of {orderingPage.smsFee} PHP for<br/>international SMS</small></p>} */}
                        <small>(exclusive of other store charges)</small>
                    </div>
                    <h4 className={classes.instruction}>
                        CASHLESS TRANSACTION
                    </h4>
                </>}
                <form>
                    <div className="form-group mb-4">
                        <label htmlFor="name">Name</label>
                        <input required
                            type        = "text"
                            id          = "name"
                            className   = "form-control"
                            value       = {contactDetails.name || ''}
                            onChange    = {e => updateContacts(e, "name")} />
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor="number">Mobile Number</label>
                        <MobileNumber
                            onCountryChange = {country => updateContacts(country, "prefix")}
                            onNumberChange  = {e => updateContacts(e, "mobile_number")}
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor="email">Email</label>
                        <input required
                            type        = "email"
                            id          = "email"
                            className   = "form-control"
                            value       = {contactDetails.email || ''}
                            onChange    = {e => updateContacts(e, "email")} />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Contacts;