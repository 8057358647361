import { PayloadAction } from "@reduxjs/toolkit";
import { delay, call, select, put, takeLatest } from "redux-saga/effects";
import { CoreApiErrorType } from "types/CoreApiErrorType";
import { request } from "utils/request";
import { LOGIN_URL, MARKETPLACE_LOGIN_URL, GET_STORES_URL } from "../core_api/resources";
import { userActions } from "./slice";
import { Login, User } from "./types";
import { RootState } from 'types/RootState';

function* login(action: PayloadAction<Login>) {
    try {
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...action.payload})
        }
        const isMerchant = window.location.pathname=="/login" || window.location.pathname=="/login/";
        if(isMerchant){
            var url = LOGIN_URL;
        }else{
            var url = MARKETPLACE_LOGIN_URL;
        }

        const user: User = yield call(request, url, options);
        if (isMerchant) {
            yield put(userActions.successfulMerchantLogin(user));
        } else {
            yield put(userActions.successfulMarketplaceLogin(user));
        }
    } catch (err) {
        const response = err.response;
        if (err && response) {
            const result = (yield call([response, 'json']))['detail'];
            yield put(userActions.apiError({result, apiError: response.status}))
        }
    }
}

export function* userSaga() {
    yield takeLatest(userActions.login.type, login)
}