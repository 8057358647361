export interface FooterProps {
    desktop         : boolean
    tablet          : boolean
    mobile          : boolean
    fromQR?         : boolean
    fromMarketplace?: boolean
    tableNo?        : number
}
export const footerClasses = {
    footerBody    : "card-body col-12 col-md-10 col-lg-4",
    cartSummary   : "d-flex justify-content-between mb-3",
    viewCartButton: "btn btn-primary btn-lg footer-button-primary",
}
