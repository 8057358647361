// React, Redux libraries
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

// Component Dependencies
import { Group } from 'types';
import { sliceKey, reducer, merchantPageActions } from '../../slice'
import { merchantPageSaga } from '../../saga'
import { selectMerchantPage } from '../../selectors';

import 'styles/modal.scss'
import { selectUser } from 'app/common/auth/selectors';
import Swal from 'sweetalert2';

interface Props {
    index: number,
    group?: Group
}

const EditModal = memo(({index, group}: Props) => {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: merchantPageSaga });
    
    const dispatch = useDispatch();
    const user = useSelector(selectUser).merchant;
    const merchantPage = useSelector(selectMerchantPage);

    const [name, setName]               = useState(group?.name);
    const [description, setDescription] = useState(group?.description);
    const [show, setShow]               = useState(false);
    const [saving, setSaving]           = useState(false);
    const [deleting, setDeleting]       = useState(false);

    const save = _ => {
        setSaving(true);
        if (user) {
            if (group) {
                const editGroup = {...group};
                editGroup.name = name || '';
                editGroup.description = description || '';
                dispatch(merchantPageActions.saveGroup({index: index, group: editGroup}))
            }
        }
    }

    useEffect(() => {
        setSaving(false);
        setDeleting(false);
        if (merchantPage.apiError === undefined) {
            setShow(false);
        }
    }, [merchantPage])
    
    const deleteGroup = _ => {
        setShow(false);
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure you want to delete this group?',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(response => {
            setShow(true);
            if (response.isConfirmed) {
                setDeleting(true);
                dispatch(merchantPageActions.deleteGroup({
                    index: index,
                    id: group?.id || -1
                }));
            }
        })
    };

    return (<>
    <div className={`modal fade ${(show && 'show')}`} tabIndex={-1} aria-modal="true" role="dialog" onClick={e => setShow(false)}>
        <div className="modal-dialog modal-dialog-centered" onClick={e => e.stopPropagation()}>
            <div className="modal-content">
                <div className="modal-body">
                    <h4 className="modal-title text-center mb-3">
                        Edit Group
                    </h4>
                    <div className="px-5">
                        <div className="form-group mb-3">
                            <label htmlFor="name">Group Name</label>
                            <input
                                type        = "text"
                                className   = "form-control"
                                placeholder = "Group Name"
                                maxLength   = {50}
                                value       = {name}
                                onChange    = {e => setName(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="description">Description</label>
                            <textarea
                                className   = "form-control"
                                placeholder = "Description"
                                value       = {description}
                                onChange    = {e => setDescription(e.target.value)}>
                            </textarea>
                        </div>
                        <button className="btn btn-primary w-100 mb-2" onClick={save} disabled={saving || deleting}>{
                            !saving ? "SAVE" :
                            <div className="spinner-border text-light" role="status"></div>
                        }</button>
                        <button className="btn btn-link text-danger w-100  mb-2" onClick={deleteGroup} disabled={saving || deleting}>{
                            !deleting ? "DELETE GROUP?" :
                            <div className="spinner-border text-danger" role="status"></div>
                        }</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {show && <div className={`modal-backdrop fade ${(show && 'show')}`}></div>}
    <button className="btn-edit" onClick={e => setShow(true)}>
        Edit
    </button>
    </>)
});

export default EditModal;