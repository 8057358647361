import { selectMerchantPage } from 'app/pages/MerchantPage/selectors';
import { merchantPageActions } from 'app/pages/MerchantPage/slice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';

import 'styles/modal.scss'
import Swal from 'sweetalert2';


const AddModal = ({group}: {group?: number}) => {
    const merchantPage = useSelector(selectMerchantPage);
    const [name, setName]               = useState<string>();
    const [amount, setAmount]           = useState<number | ''>();
    const [description, setDescription] = useState<string>();
    const [image, setImage]             = useState<string>();
    const [show, setShow]               = useState(false);
    const [saving, setSaving]           = useState(false);

    const {getRootProps, getInputProps} = useDropzone();
    const dispatch = useDispatch();

    useEffect(() => {
        setSaving(false);
        if (merchantPage.apiError === undefined) {
            setName('');
            setDescription('');
            setAmount('');
            setImage('');
            setShow(false);
        }
    }, [merchantPage])
    const groupIndex = group !== undefined ? group : -1;
    const save = _ => {
        if (!name || amount === '') {
            Swal.fire({
                icon: 'error',
                title: 'Please complete details',
            })
            return;
        }
        setSaving(true);
        dispatch(merchantPageActions.saveItem({
            groupIndex: groupIndex,
            item: {
                store_category_id: (merchantPage.groups ? merchantPage.groups[groupIndex]?.id : -1) || -1,
                name: name || '',
                description: description || '',
                price: +(amount || 0),
                image_url: image
            }
        }))
    }
    const getImageUri = async e => {
        if (e.target.files) {
            const file = e.target.files[0];
            const name = file.name;
            const lastDot = name.lastIndexOf('.');
            const ext = name.substring(lastDot + 1);
            try {
                if (!['jpg', 'jpeg', 'png'].includes(ext)) {
                    e.target.value = '';
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Image type is not allowed',
                    })
                    return;
                }
                const compressedFile = await imageCompression(file, {
                    maxSizeMB: 0.5,
                    maxWidthOrHeight: 400,
                    useWebWorker: true
                });
                setImage(await imageCompression.getDataUrlFromFile(compressedFile))
            } catch (error) {
                console.log(error)
            }
        }
    }

    return (<>
    <div className={`modal fade ${(show && 'show')}`} tabIndex={-1} aria-modal="true" role="dialog" onClick={e => setShow(false)}>
        <div className="modal-dialog modal-dialog-centered" onClick={e => e.stopPropagation()}>
            <div className="modal-content">
                <div className="modal-body">
                    <h4 className="modal-title text-center mb-3">
                        Add Item
                    </h4>
                    <div className="px-5">
                        <div className="form-group mb-3">
                            <label htmlFor="name">Item Name</label>
                            <input
                                type        = "text"
                                className   = "form-control"
                                placeholder = "Item Name"
                                maxLength   = {50}
                                value       = {name}
                                onChange    = {e => setName(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="description">Description</label>
                            <textarea
                                id          = "name"
                                className   = "form-control"
                                placeholder = "Description"
                                value       = {description}
                                onChange    = {e => setDescription(e.target.value)}>
                            </textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="name">Amount (PHP)</label>
                            <input
                                type        = "number"
                                className   = "form-control"
                                placeholder = "0.00"
                                value       = {amount}
                                onChange    = {e => setAmount(+e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="image">Image</label>
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} onChange={getImageUri} />
                                {!image ?
                                    <div className="text-center">
                                        <p>Click to upload</p>
                                        <small>jpg, jpeg, png (max 400 x 400, 500 kb)</small>
                                    </div> :
                                    <img src={image} alt={name} width="100%"/>
                                }
                            </div>
                        </div>
                        <button className="btn btn-primary w-100 mb-2" onClick={save} disabled={saving}>{
                            !saving ? "SAVE" :
                            <div className="spinner-border text-light" role="status"></div>
                        }</button>
                        <button className="btn btn-link text-primary w-100  mb-2" onClick={e => setShow(false)} disabled={saving}>CANCEL</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {show && <div className={`modal-backdrop fade ${(show && 'show')}`} onClick={e => setShow(false)}></div>}
    <button className="btn-add" onClick={e => setShow(true)}>
        Add Item
    </button>
    </>)
};

export default AddModal;