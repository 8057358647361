import { PayloadAction } from '@reduxjs/toolkit';
import { CoreApiErrorType } from 'types/CoreApiErrorType';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
    ContainerState,
    User
} from './types';


// The initial state of the ReportsPage container
export const initialState: ContainerState = {
    groupsLoading: true,
    usersLoading: true,
    apiError: undefined,
    showAddModal: false,
    showEditModal: false,
    posting: true,
    trigger: 0,
    validationError: 0,
    generating: true,
    uploadingQr: false,
    qrCode: '',
};

const adminPageSlice = createSlice({
    name: 'adminPage',
    initialState,
    reducers: {
        getUsers(state, action: PayloadAction<number | undefined>) {
            state.admin_id = action.payload;
            state.usersLoading = true;
        },
        usersLoaded(state, action: PayloadAction<Array<User>>) {
            state.users = action.payload;
            state.usersLoading = false;
        },
        searchUsers(state, action: PayloadAction<string | undefined>) {
            state.keyword = action.payload;
            state.usersLoading = true;
        },
        addUser(state, action: PayloadAction<{
            user?: User
        }>) {
            state.posting = true;
        },
        addedUser(state, action: PayloadAction<number>) {
            state.posting = false;
            state.trigger = action.payload;
        },
        saveUser(state, action: PayloadAction<{
            user?: User
        }>) {
            state.posting = true;
        },
        savedUser(state, action: PayloadAction<number>) {
            state.posting = false;
            state.trigger = action.payload;
        },
        deleteUser(state, action: PayloadAction<{
            id: number
        }>) {
            state.posting = true;
        },
        deletedUser(state, action: PayloadAction<number>) {
            state.posting = false;
            state.trigger = action.payload;
        },
        generate(state, action: PayloadAction<{username: string|undefined}>) {
            state.generating = true;
        },
        generated(state, action: PayloadAction<any>) {
            state.qrCode = action.payload;
            state.generating = false;
        },
        uploadQR(state, action: PayloadAction<{username: string, image: any}>) {
            state.uploadingQr = true;
        },
        uploadedQR(state) {
            state.uploadingQr = false;
        },
        validationError(state, action: PayloadAction<number>) {
            state.validationError = action.payload;
        },
        apiError(state, action: PayloadAction<CoreApiErrorType>) {
            state.posting = false;
            state.apiError = action.payload;
        },
    },
});

export const {
    actions: adminPageActions,
    reducer,
    name: sliceKey,
} = adminPageSlice;
