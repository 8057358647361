import React, { useEffect, useState } from "react";
import EditModal from "./EditUserModal";
import { Item as ItemProps } from '../../types';

import { 
    Typography, 
    Modal, 
    Backdrop, 
    Fade, Grid, 
    Paper, 
    FormControlLabel, 
    Button, 
    Divider, 
    Radio,  
    Card,
} from '@material-ui/core/';

import './styles.scss';
import { useDispatch, useSelector } from "react-redux";
import { userPageActions } from "../../slice";
import { selectUserPage } from "../../selectors";

export interface Props {
    data: any
}

const User = (props: Props) => {
    
    const userPage = useSelector(selectUserPage);
    const [updating, setUpdating]   = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        
    }, [userPage.trigger, userPage.userLoading])

    return (
        <Card className="item" style={{padding: "10px 25px", marginBottom: "10px"}}>
            <div style={{maxHeight: "170px", maxWidth: "250px", height: "170px", minWidth: "250px"}}>
            
                <img src={props.data.avatar_url} alt={props.data.name} width="100%" height="100%"/>
            </div>
            <div className="item-details">
                <Typography variant="h5" className="item-name">{props.data.name}</Typography>
                <Typography style={{ color: "#9a9a9c"}}
                    dangerouslySetInnerHTML = {{ __html: props.data.description.replaceAll('\n', '<br/>')}}
                />
                <Typography style={{ color: "#9a9a9c"}}>Dispatcher's Contact #:<br/>{props.data.dispatcher_contact}</Typography>
            </div>
            <div className="item-actions">
                <EditModal data={props.data} />
            </div>
        </Card>
    )
}

export default User;