import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Swal from 'sweetalert2';
import useEmailVerifier from "utils/emailVerifier";
import { FooterProps, footerClasses } from "../footer";
import { selectOrderingPage } from "../selectors";
import { orderingPageActions } from "../slice";

let EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const Footer = ({fromQR, fromMarketplace, tableNo}: FooterProps) => {
    const dispatch = useDispatch();
    const orderingPage = useSelector(selectOrderingPage);
    const validateEmail = useEmailVerifier();
    const [placedOrder, setPlacedOrder] = useState(orderingPage.placedOrder);
    const [placingOrder, setPlacingOrder] = useState(false);
    const [amount, setAmount] = useState('0.00');
    
    useEffect(() => {
        setPlacingOrder(false);
        setPlacedOrder(orderingPage.placedOrder);
        if (orderingPage.cart && orderingPage.cart.length > 0) {
            var total = 0;
            for (const cart of orderingPage.cart) {
                total += cart.quantity*cart.item.price;
            }
            if (fromMarketplace) {
                total += orderingPage.dispatcherFee || 0;
            }
            if (orderingPage.contacts.mobile_number?.country != 'PH') {
                // total += orderingPage.smsFee || 0;
            }
            setAmount(total.toLocaleString('en-US', {minimumFractionDigits: 2}));
        } else {
            setAmount("0.00");
        }
    }, [placedOrder, orderingPage.placedOrder, orderingPage.contacts])

    const submit = payWithCash => {
        if (
            !orderingPage.contacts.name ||
            !orderingPage.contacts.mobile_number ||
            !orderingPage.contacts.email
        ) {
            Swal.fire({
                icon: 'error',
                title: 'Please complete your contact information',
            });
        } else if (
            !orderingPage.contacts.name ||
            orderingPage.contacts.name.split(' ').length < 2 ||
            orderingPage.contacts.name.split(' ').filter(name => name != null && name.trim() !== '').length < 2
        ) {
            Swal.fire({
                icon: 'error',
                title: 'Please enter your full name'
            })
        // } else if (orderingPage.contacts.mobile_number.length !== 11) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: 'Mobile number must consists of 11 digits '
        //     })
        } else if (!orderingPage.contacts.mobile_number.number) {
            Swal.fire({
                icon: 'error',
                title: "Please enter your mobile number"
            })
        } else if (!orderingPage.contacts.mobile_number.prefix) {
            Swal.fire({
                icon: 'error',
                title: "Please enter your mobile number's country code"
            })
        } else if (!orderingPage.contacts.email?.match(EMAIL_REGEX)) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid email',
                text: 'Please check your email address'
            });
        } else {
            setPlacingOrder(true)
            validateEmail(orderingPage.contacts.email, (isValid, error) => {
                if (isValid) {
                    dispatch(orderingPageActions.placeOrder({
                        payWithCash,
                        order_method: (fromQR ? 'contactless' : 'online'),
                        fromMarketplace: !!fromMarketplace,
                        tableNo
                    }));
                } else {
                    setPlacingOrder(false)
                    Swal.fire({
                        icon: 'error',
                        title: error,
                        text: 'Please use a valid email address'
                    });
                }
            })
        }
    }

    if (placedOrder && (orderingPage.payLater || orderingPage.payWithCash)) {
        return <Redirect to={`/${orderingPage.merchant.username}/place-order`} />;
    }
    if (!placedOrder) {
        return (
            <div className={footerClasses.footerBody}>
                <div className={footerClasses.cartSummary}>
                    <Link to={`/${orderingPage.merchant.username}/cart`} className="text-decoration-none">
                        <small className="text-muted">Go Back</small>
                    </Link>
                    <small className="fw-bold">₱ {amount}</small>
                </div>
                <button className={footerClasses.viewCartButton} onClick={e => submit(false)} disabled={placingOrder}>{
                    !placingOrder ? "Continue" :
                    <div className="spinner-border text-light" role="status"></div>
                }</button>
                {/* {fromQR && <button className="btn footer-button-secondary" onClick={e => submit(true)} disabled={placingOrder}>or Pay with Cash</button>} */}
            </div>
        )
    }
    window.onbeforeunload = null;
    window.location.href = orderingPage.paymentPage;
    return null;
}

export default Footer;