import { selectUser } from 'app/common/auth/selectors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'

import { 
    TextField, 
    InputAdornment,
    CircularProgress,
} from '@material-ui/core/';

import 'styles/modal.scss'
import { selectUserPage } from '../../selectors';
import { userPageActions } from '../../slice';
import { STORE_NAME } from 'app/common/core_api/resources';

const AddModal = () => {
    const [show, setShow]                           = useState(false);
    const [name, setName]                           = useState<string>('');
    const [description, setDescription]             = useState<string>('');
    const [image, setImage]                         = useState<string>('');
    const [url, setURL]                             = useState<string>('');
    const [contact, setContact]                     = useState<string>('');
    const [tags, setTags]                           = useState<string>('');
    const [saving, setSaving]                       = useState(false);

    const dispatch     = useDispatch();
    const user         = useSelector(selectUser).user;
    const userPage = useSelector(selectUserPage);

    const {getRootProps, getInputProps} = useDropzone();

    useEffect(() => {
        setSaving(false);
        setName('');
        setDescription('');
        setImage('');
        setURL('');
        setContact('');
        setTags('');
        if (userPage.apiError === undefined) {
            setShow(false);
        }
    }, [userPage.trigger])

    useEffect(() => {
        setSaving(false);
    }, [userPage.validationError, userPage.apiError])

    useEffect(() => {
        dispatch(userPageActions.loadStoreName(''))
        dispatch(userPageActions.getStoreName(url))
    }, [url])
    
    useEffect(() => {
        if (show) {
            dispatch(userPageActions.loadStoreName(''))
            dispatch(userPageActions.getStoreName(url))
        }
    }, [show])
    
    const save = _ => {
        var value = tags.replace(/\s/g, "");
        var words = value.split(",");
        var urlRestricted = url.includes("admin")
        var urlRestricted1 = url.includes("dashboard")

        if (!userPage.selectedStoreName) {
            Swal.fire({
                icon : 'error',
                title: 'Oops...',
                text : 'Username does not exist or wait for the name to show',
            })
        }
        else if(!description || !url || !contact){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill all required fields (*)',
            })
        }else if(urlRestricted || urlRestricted1){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid GoContactless URL. `Admin` and `dashboard` is a system reserved word and cannot be used.',
            })
        }else if(words.length > 5){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Only maximum of 5 tags are allowed',
            })
        }else if(words.includes("") && value!=""){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Invalid tags. Please make sure there is no blank tag in between, before and after commas.',
            })
        }else{
            setSaving(true);
            setTags(tags.replace(/\s/g, " "))
            if (user) {
                dispatch(userPageActions.addStore({
                    store:{
                        marketplace_user_id: user.id,
                        name          : name || '',
                        description   : description || '',
                        contact_number : contact || '',
                        contactless_url : url || '', 
                        tags : tags || '',
                        logo_url : image || '', 
                        available : 'true',
                    }
                }))
            }
        }
    }

    const getImageUri = e => {
        if (e.target.files) {
            const file = e.target.files[0];
            const name = file.name;
            const lastDot = name.lastIndexOf('.');
            const ext = name.substring(lastDot + 1);
            if (file.size > 1000000) {
                e.target.value = '';
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'File size exceeds limit, it should be not more than 1MB',
                })
                return;
            }
            if (!['jpg', 'jpeg', 'png'].includes(ext)) {
                e.target.value = '';
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'File type is not allowed',
                })
                return;
            }
            
            if (file) {
                var reader = new FileReader();
                reader.onload = function (data) {
                    setImage(String(data.target?.result));
                }
                reader.readAsDataURL(file);
            }
            else {
                console.log('File not found');
            }
        }
    }

    const cancelButton = () => {
        setSaving(false);
        setName('');
        setDescription('');
        setImage('');
        setURL('');
        setContact('');
        setTags('');
        setShow(false);
    }

    const isNumber = (evt) => {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        if (key.length == 0) return;
        var regex = /^[ +)(0-9,\b-]+$/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    return (<>
    <div className={`modal fade ${(show && 'show')}`} tabIndex={-1} aria-modal="true" role="dialog" onClick={e => setShow(false)}>
        <div className="modal-dialog modal-dialog-centered modal-lg" onClick={e => e.stopPropagation()}>
            <div className="modal-content">
                <div className="modal-body">
                    <h4 className="modal-title text-center mb-3">
                        Add Establishment
                    </h4>
                    <div className="px-5">
                        <div className="form-group mb-3">
                            <label htmlFor="name">
                                {!userPage.selectedStoreName && url && <><CircularProgress style={{width: '15px', height:'15px'}} />{' '}</>}
                                Name *
                            </label>
                            <input disabled
                                id          = "name"
                                type        = "text"
                                className   = "form-control"
                                maxLength   = {50}
                                value       = {userPage.selectedStoreName}
                                onChange    = {e => setName(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="description">Description *</label>
                            <textarea
                                id          = "description"
                                className   = "form-control"
                                maxLength   = {150}
                                value       = {description}
                                onChange    = {e => setDescription(e.target.value)}>
                            </textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="contact">Contact Number *</label>
                            <input
                                id          = "contact"
                                type        = "text"
                                className   = "form-control"
                                maxLength   = {50}
                                value       = {contact}
                                onChange    = {e => setContact(e.target.value)} 
                                onKeyPress  = {e => isNumber(e)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="url" style={{ marginBottom: '5px' }}>GoContactless URL *</label>
                            <TextField
                                id          = "url"
                                className   = "form-control"
                                InputProps  = {{
                                    startAdornment: <InputAdornment position="start">{process.env.REACT_APP_URL}/</InputAdornment>,
                                }}
                                variant     = "outlined"
                                size        = "small"
                                value       = {url}
                                onChange    = {e => setURL(e.target.value)}
                            />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="tags">Tags (max 5, use comma as seperator)</label>
                            <input
                                id          = "tags"
                                type        = "text"
                                className   = "form-control"
                                maxLength   = {150}
                                value       = {tags}
                                onChange    = {e => setTags(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="image">Image</label>
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} onChange={getImageUri} />
                                {!image ?
                                    <div className="text-center">
                                        <p>Click to upload</p>
                                        <small>jpg, jpeg, png (recommended 400 x 400, max 1 mb)</small>
                                    </div> :
                                    <img src={image} alt={name} width="100%"/>
                                }
                            </div>
                        </div>
                        <button className="btn btn-primary w-100 mb-2" onClick={save} disabled={saving}>{
                            !saving ? "SAVE" :
                            <div className="spinner-border text-light" role="status"></div>
                        }</button>
                        <button className="btn btn-link text-primary w-100  mb-2" onClick={e => cancelButton()} disabled={saving}>CANCEL</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {show && <div className={`modal-backdrop fade ${(show && 'show')}`} onClick={e => setShow(false)}></div>}
    <button className="btn-add" onClick={e => setShow(true)}>
        Add
    </button>
    </>)
}

export default AddModal;