import { Store } from "types";

export function timeNowInRange(start, end) {
    if (!start || !end) {
        return false;
    }
    let startTime = time(start);
    let endTime   = time(end);
    let now       = new Date();
    return startTime <= now && now <= endTime;
}

export function time(string) {
    let [hour, min] = string.split(":")
    let time = new Date();
    time.setHours(hour);
    time.setMinutes(min);
    time.setSeconds(0);
    return time;
}

export const weekdays = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
]

export function storeIsOpen(merchant: Store) {
    let day     = new Date().getDay();
    let weekend = day == 0 || day == 6;
    return (
        !merchant.operation_hours ||
        Object.keys(merchant.operation_hours).length == 0 ||
        timeNowInRange(
            merchant.operation_hours?.everyday?.start,
            merchant.operation_hours?.everyday?.end
        ) ||
        (weekend && timeNowInRange(
            merchant.operation_hours?.weekends?.start,
            merchant.operation_hours?.weekends?.end
        )) ||
        (!weekend && timeNowInRange(
            merchant.operation_hours?.weekdays?.start,
            merchant.operation_hours?.weekdays?.end
        )) ||
        (timeNowInRange(
            merchant.operation_hours[weekdays[day]]?.start,
            merchant.operation_hours[weekdays[day]]?.end,
        ))
    )
}

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)