import { useEffect, useState } from 'react';

import 'styles/modal.scss'
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { selectMerchantPage } from '../../selectors';
import { merchantPageActions } from '../../slice';
import { Props } from '.';
import { Item } from 'types';
import Swal from 'sweetalert2';
import imageCompression from 'browser-image-compression';

const EditModal = (props: Props) => {
    const merchantPage = useSelector(selectMerchantPage);
    const [item, setItem]         = useState<Item>(props.item);
    const [show, setShow]         = useState(false);
    const [saving, setSaving]     = useState(false);
    const [deleting, setDeleting] = useState(false);

    const {getRootProps, getInputProps} = useDropzone();
    useEffect(() => {
        setSaving(false);
        setDeleting(false);
        if (merchantPage.apiError === undefined) {
            setShow(false);
        }
    }, [merchantPage])
    
    const dispatch = useDispatch();
    const updateItem = (value, index) => {
        const updateItem = {...item};
        updateItem[index] = index === "price" ? +value : value;
        setItem(updateItem);
    };
    const save = _ => {
        setSaving(true);
        dispatch(merchantPageActions.saveItem({
            groupIndex: props.groupIndex,
            index: props.index,
            item: item
        }))
    };
    const deleteItem = _ => {
        setShow(false);
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure you want to delete this item?',
            showCancelButton: true,
            confirmButtonColor: '#dc3545',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then(response => {
            setShow(true);
            if (response.isConfirmed) {
                setDeleting(true);
                dispatch(merchantPageActions.deleteItem({
                    groupIndex: props.groupIndex,
                    index: props.index,
                    id: item?.id || -1
                }))
            }
        })
    };
    const getImageUri = async e => {
        if (e.target.files) {
            const file = e.target.files[0];
            const name = file.name;
            const lastDot = name.lastIndexOf('.');
            const ext = name.substring(lastDot + 1);
            try {
                if (!['jpg', 'jpeg', 'png'].includes(ext)) {
                    e.target.value = '';
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Image type is not allowed',
                    })
                    return;
                }
                const compressedFile = await imageCompression(file, {
                    maxSizeMB: 0.5,
                    maxWidthOrHeight: 400,
                    useWebWorker: true
                });
                const updateItem = {...item};
                updateItem.image_url = await imageCompression.getDataUrlFromFile(compressedFile)
                setItem(updateItem);
            } catch (error) {
                console.log(error)
            }
        }
    }


    return (<>
    <div className={`modal fade ${(show && 'show')}`} tabIndex={-1} aria-modal="true" role="dialog" onClick={() => setShow(!show)}>
        <div className="modal-dialog modal-dialog-centered" onClick={e => e.stopPropagation()}>
            <div className="modal-content">
                <div className="modal-body">
                    <h4 className="modal-title text-center mb-3">
                        Edit Item
                    </h4>
                    <div className="px-5">
                        <div className="form-group mb-3">
                            <label htmlFor="name">Item Name</label>
                            <input
                                type        = "text"
                                className   = "form-control"
                                placeholder = "Item Name"
                                maxLength   = {50}
                                value       = {item.name}
                                onChange    = {e => updateItem(e.target.value, "name")} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="description">Description</label>
                            <textarea
                                className   = "form-control"
                                placeholder = "Description"
                                value       = {item.description}
                                onChange    = {e => updateItem(e.target.value, "description")}>
                            </textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="name">Amount (PHP)</label>
                            <input
                                type        = "number"
                                className   = "form-control"
                                placeholder = "0.00"
                                value       = {item.price}
                                onChange    = {e => updateItem(e.target.value, "price")} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="image">Image</label>
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} onChange={getImageUri} />
                                {!item.image_url ?
                                    <div className="text-center">
                                        <p>Click to upload</p>
                                        <small>jpg, jpeg, png (max 400 x 400, 500 kb)</small>
                                    </div> :
                                    <img src={item.image_url} alt={item.name} width="100%"/>
                                }
                            </div>
                        </div>
                        <button className="btn btn-primary w-100 mb-2" disabled={saving || deleting} onClick={save}>{
                            !saving ? "SAVE" :
                            <div className="spinner-border text-light" role="status"></div>
                        }</button>
                        <button className="btn btn-link text-danger w-100  mb-2" disabled={saving || deleting} onClick={deleteItem}>{
                            !deleting ? "DELETE ITEM?" :
                            <div className="spinner-border text-danger" role="status"></div>
                        }</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {show && <div className={`modal-backdrop fade ${(show && 'show')}`}></div>}
    <button className="btn-edit" onClick={e => setShow(true)}>
        Edit
    </button>
    </>)
}

export default EditModal;