import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import { FooterProps, footerClasses } from "../footer";
import { selectOrderingPage } from "../selectors";
import { orderingPageActions } from "../slice";

export const Footer = ({fromMarketplace}: FooterProps) => {
    const dispatch = useDispatch();
    const orderingPage = useSelector(selectOrderingPage);
    const [amount, setAmount] = useState('0.00');
    useEffect(() => {
        if (orderingPage.cart && orderingPage.cart.length > 0) {
            var total = 0;
            for (const cart of orderingPage.cart) {
                total += cart.quantity*cart.item.price;
            }
            setAmount(total.toLocaleString('en-US', {minimumFractionDigits: 2}));
        } else {
            setAmount("0.00");
        }
    }, [orderingPage.cart])

    const pay = (e, payLater: boolean) => {
        if (!orderingPage.contacts.notes) {
            Swal.fire({
                icon: 'error',
                title: 'Empty Notes',
                text: 'Please fill out the notes'
            })
            e.preventDefault()
        }
        var total = 0;
        for (const item of  orderingPage.cart) {
            total += (item.item.price*item.quantity);
        }
        if (total >= (orderingPage.minimumPurchase || 300)) {
            dispatch(orderingPageActions.payLater(payLater))
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Insufficient total',
                text: `Minimum purchase is ${(orderingPage.minimumPurchase || 300).toLocaleString('en-US', {minimumFractionDigits: 2})} PHP. Please add more to cart`
            })
            e.preventDefault()
        }
    }

    return (
        <div className={footerClasses.footerBody}>
            <div className={footerClasses.cartSummary}>
                <Link to={`/${orderingPage.merchant.username}`} className="text-decoration-none">
                    <small className="text-muted">Go Back</small>
                </Link>
                <small className="fw-bold">₱ {amount}</small>
            </div>
            <Link to={`/${orderingPage.merchant.username}/contacts`} className={footerClasses.viewCartButton} onClick={e => pay(e, false)}>PLACE ORDER & PAY NOW</Link>
            {/* {fromQR && <Link to={`/${orderingPage.merchant.username}/contacts`} className="btn footer-button-secondary" onClick={e => pay(e, true)}>Place Order & Pay Later</Link>} */}
        </div>
    )
}

export default Footer;