import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { selectAdminPage } from "../../selectors";
import { adminPageActions } from "../../slice";

import 'styles/modal.scss';

const UploadQRModal = ({state: [show, setShow]}: {state: any}) => {
    const dispatch = useDispatch();
    const adminPage = useSelector(selectAdminPage);

    const [saving, setSaving]     = useState(false);
    const [image, setImage]       = useState<string>('');
    const [username, setUsername] = useState<string>('');

    const handleCancel = () => {
        setSaving(false);
        setImage('');
        setShow(false);
    }

    useEffect(() => {
        !adminPage.uploadingQr && setSaving(adminPage.uploadingQr)
    }, [adminPage.uploadingQr])

    const getQRImageUri = e => {
        if (e.target.files[0]) {
            const file = e.target.files[0];
            const name = file.name;
            const lastDot = name.lastIndexOf('.');
            const ext = name.substring(lastDot + 1);
            if (!['jpg', 'jpeg', 'png'].includes(ext)) {
                e.target.value = '';
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'File type is not allowed. Please upload a jpg, jpeg, or png file.',
                })
                return;
            }
            
            if (file) {
                var reader = new FileReader();
                reader.onload = function (data) {
                    setImage(String(data.target?.result));
                }
                reader.readAsDataURL(file);
            }
            else {
                console.log('File not found');
            }
        }
    }

    const save = () => {
        if (image && username) {
            setSaving(true);
            return dispatch(adminPageActions.uploadQR({image, username}))
        }
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'All fields are required',
        })
    }
    return (<>
        <div className={`modal fade ${(show && 'show')}`} tabIndex={-1} aria-modal="true" role="dialog" onClick={e => setShow(false)}>
            <div className="modal-dialog shadow" onClick={e => e.stopPropagation()}>
                <div className="modal-content">
                    <div className="modal-body">
                        <h4 className="modal-title text-center mb-3">
                            Add User
                        </h4>
                        <div className="px-5">
                            <div className="form-group mb-3">
                                <label htmlFor="username">Username *</label>
                                <input
                                    type        = "text"
                                    className   = "form-control"
                                    maxLength   = {50}
                                    value       = {username}
                                    onChange    = {e => setUsername(e.target.value)} />
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="image">QR Code *</label>
                                <div className="dropzone">
                                    <input type="file" onChange={getQRImageUri} id="image"/>
                                    {!image ?
                                        <div className="text-center">
                                            <small>jpg, jpeg, png (recommended 300 x 300, max 1 mb)</small>
                                        </div> :
                                        <img src={image} width="100%"/>
                                    }
                                </div>
                            </div>
                            <button className="btn btn-primary w-100 mb-2" onClick={save} disabled={saving}>{
                                !saving ? "UPLOAD" :
                                <div className="spinner-border text-light" role="status"></div>
                            }</button>
                            <button className="btn btn-link text-primary w-100  mb-2" onClick={e => setShow(false)} disabled={saving}>CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default UploadQRModal;