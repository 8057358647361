import { delay, call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { CoreApiErrorType } from 'types/CoreApiErrorType';

import { RootState } from 'types/RootState';
import { request } from 'utils/request';
import { userPageActions } from './slice';
import Swal from 'sweetalert2';

import {
    STORE_AVAILABLE_URL, GET_USER_URL, DELETE_STORE_URL, SAVE_STORE_URL, ADD_STORE_URL, STORES_URL, SAVE_USER_URL, TOGGLE_STORE, STORE_NAME
} from 'app/common/core_api/resources';
import { Group, Item, User, Store } from './types';
import { PayloadAction } from '@reduxjs/toolkit';


export function* getUser(action: PayloadAction<{
    username
}>) {
    yield delay(1000);
  
    try {
        let getUserId = (state: RootState) => action.payload;
        let id: string = yield select(getUserId);

        let url = GET_USER_URL.replace(':username', id);
        
        const data = yield call(request, url);
        
        data.userLoading = true;

        yield put(userPageActions.userLoaded(data));
    } catch (err) {
        if (err) {
            yield put(
                userPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* getStores() {
    yield delay(1000);
  
    try {
        let getUserId = (state: RootState) => state?.userPage?.marketplace_user_id;
        let id: string = yield select(getUserId);

        let url = STORES_URL.replace(':marketplace_user_id', id);

        const data = yield call(request, url);
        for (const item of data) {
            item.storesLoading = true;
        }
        
        yield put(userPageActions.storesLoaded(data));
    } catch (err) {
        if (err) {
            yield put(
                userPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* addStore(action: PayloadAction<{
    store?: Store
}>) {
    yield delay(1000);
    var store = action.payload
    try {
        // Just to trigger useEffect, I use time for uniqueness
        let trigger = Math.random()
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...store})
        }
        let url = ADD_STORE_URL;
        store = (yield call(request, url, options))[0];
        if(store=='store_name_already_exist'){
            Swal.fire('Establishment name already exist on your marketplace. Please try another.', '', 'error')
            yield put(userPageActions.validationError(trigger));
        }else if (store=='not_exist'){
            Swal.fire('Invalid GoContactless URL. Username do not exist.', '', 'error')
            yield put(userPageActions.validationError(trigger));
        }else{
            Swal.fire('Establishment added successfully', '', 'success')
            yield put(userPageActions.addedStore(trigger));
            yield call(getStores);
        }
        
    } catch (err) {
        let trigger = Math.random()
        if (err) {
            yield put(
                userPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR + trigger),
            );
            Swal.fire('An error occured', '', 'error')
        }
    }
}

export function* saveStore(action: PayloadAction<{
    store?: Store
}>) {
    yield delay(1000);
    var store = action.payload
    try {
        // Just to trigger useEffect, I use time for uniqueness
        let trigger = Math.random()
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...store})
        }
        let url = SAVE_STORE_URL;
        store = (yield call(request, url, options))[0];
        if(store=='store_name_already_exist'){
            Swal.fire('Establishment name already exist on your marketplace. Please try another.', '', 'error')
            yield put(userPageActions.validationError(trigger));
        }else if (store=='not_exist'){
            Swal.fire('Invalid GoContactless URL. Username do not exist.', '', 'error')
            yield put(userPageActions.validationError(trigger));
        }else{
            Swal.fire('Changes successfully saved', '', 'success')
            yield put(userPageActions.savedStore(trigger));
            yield call(getStores);
        }
        
    } catch (err) {
        let trigger = Math.random()
        if (err) {
            yield put(
                userPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR + trigger),
            );
            Swal.fire('An error occured', '', 'error')
        }
    }
}

export function* deleteStore(action: PayloadAction<{id: number}>) {
    yield delay(1000);
    var id = action.payload
    try {
        let trigger = Math.random()
        let options: RequestInit = {
            method: "DELETE",
        }
        let url = DELETE_STORE_URL.replace(':id', (id.id || '').toString());
        
        yield call(request, url, options);
        yield put(userPageActions.deletedStore(trigger));
        Swal.fire('Establishment removed successfully', '', 'success')
        yield call(getStores);
    } catch (err) {
        let trigger = Math.random()
        if (err) {
            yield put(
                userPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR + trigger),
            );
            Swal.fire('An error occured', '', 'error')
        }
    }
}

export function* saveUser(action: PayloadAction<{
    user?: User
}>) {
    yield delay(1000);
    var user = action.payload
    let username = user.user?.username ? user.user?.username : '';
    try {
        let trigger = Math.random()
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...user})
        }
        let url = SAVE_USER_URL;
        user = (yield call(request, url, options))[0];
        yield put(userPageActions.savedUser(trigger));
        yield call(userPageActions.getUser, username);
        Swal.fire('Changes Successfully saved', '', 'success')
    } catch (err) {
        let trigger = Math.random()
        if (err) {
            yield put(
                userPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR + trigger),
            );
            Swal.fire('An error occured', '', 'error')
        }
    }
}

export function* setAvailable(action: PayloadAction<{id: number}>) {
    yield delay(1000);
    var id = action.payload
    try {
        let available_trigger = Math.random()
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({...id})
        }
        let url = STORE_AVAILABLE_URL.replace(':id', (id.id || '').toString());
        
        yield call(request, url, options);
        yield put(userPageActions.availableUpdated(available_trigger));
        //Swal.fire('Establishment removed successfully', '', 'success')
    } catch (err) {
        let available_trigger = Math.random()
        if (err) {
            yield put(
                userPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR + available_trigger),
            );
            Swal.fire('An error occured', '', 'error')
        }
    }
}

export function* toggleStore(action: PayloadAction<string>) {
    yield delay(1000);
    try {
        let username: string = action.payload;
        let options: RequestInit = {
            method: "POST",
            headers: {'Content-Type': 'application/json'}
        }
        let url  = TOGGLE_STORE.replace(':username', username);
        yield call(request, url, options);
        yield call(getStores);
    } catch (err) {
        if (err) {
            yield put(
                userPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* getStoreName(action: PayloadAction<string>) {
    yield delay(1000);
    try {
        let url       = STORE_NAME.replace(':username', action.payload);
        let storeName = yield call(request, url);
        yield put(userPageActions.loadStoreName(storeName))
    } catch (err) {
        if (err) {
            console.log(err)
            yield put(
                userPageActions.apiError(CoreApiErrorType.INTERNAL_SERVER_ERROR),
            );
        }
    }
}

export function* userPageSaga() {
    yield takeLatest(userPageActions.getUser.type, getUser)
    yield takeLatest(userPageActions.saveUser.type, saveUser)
    yield takeLatest(userPageActions.getStores.type, getStores)
    yield takeLatest(userPageActions.addStore.type, addStore)
    yield takeLatest(userPageActions.saveStore.type, saveStore)
    yield takeLatest(userPageActions.deleteStore.type, deleteStore)
    yield takeLatest(userPageActions.setAvailable.type, setAvailable)
    yield takeLatest(userPageActions.toggleStore.type, toggleStore)
    yield takeLatest(userPageActions.getStoreName.type, getStoreName)
}