import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import 'styles/order_placed.scss';
import { selectOrderingPage } from './selectors';

const classes = {
    footer: "row w-100 justify-content-center px-4",
    payButton: "btn pay-button col-12 col-lg-6",
}

const OrderPlaced = () => {
    const orderingPage = useSelector(selectOrderingPage);

    var total = 0;
    for (const item of orderingPage.cart) {
        total += item.quantity*item.item.price;
    }

    const pay = _ => window.location.href = orderingPage.paymentPage;
    
    const PayLater = _ => (
        <div>
            <h4 className="fw-bold text-success">CASHLESS TRANSACTION</h4>
            <h5 className="text-secondary">
                You will receive an Email and SMS on how to pay.
            </h5>
        </div>
    )
    const PayWithCash = _ => (
        <div>
            <div className="orders text-start p-3">
                <p className="mb-0">Orders:</p>
                {orderingPage.cart.map(item => 
                    <div className="d-flex justify-content-between mb-0">
                        <span>{item.item.name} x {item.quantity}</span>
                        <span className="fw-bold">P{(item.item.price*item.quantity).toLocaleString('en-US', {minimumFractionDigits: 2})}</span>
                    </div>
                )}
            </div>
            <div className="text-start p-3">
                <p className="mb-0">Notes:</p>
                <p className="mb-0">{orderingPage.contacts.notes}</p>
            </div>
        </div>
    )
    if (orderingPage.cart.length == 0) {
        return <Redirect to={`/${orderingPage.merchant.username}`} />
    }
    return (
        <div className="order-placed">
            <div className="content">
                {orderingPage.payWithCash && <small className="fst-italic mt-3 mb-5">Show this to the store attendant</small>}
                <h2 className="fw-bold text-success mb-0">
                    {orderingPage.payLater && orderingPage.payWithCash && 'YOU ARE PAYING LATER WITH CASH'}
                    {orderingPage.payLater && !orderingPage.payWithCash && 'COMPLETE YOUR TRANSACTION'}
                    {!orderingPage.payLater && orderingPage.payWithCash && 'YOU ARE PAYING WITH CASH'}
                </h2>
                <h5 className="text-secondary mb-0">Total amount to pay</h5>
                <h5 className="fw-bold text-dark">₱ {total.toLocaleString('en-US', {minimumFractionDigits: 2})}</h5>
                <small className="mb-5">(exclusive of other store charges)</small>
                {orderingPage.payWithCash ? <PayWithCash /> : <PayLater /> }
            </div>
            <div className={classes.footer}>
                {orderingPage.payLater && !orderingPage.payWithCash && <button className={classes.payButton} onClick={pay}>Ready to pay? Click here</button>}
            </div>
        </div>
    )
}

export default OrderPlaced;