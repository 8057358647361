import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import GoogleAnalytics from 'app/common/ga';

import maintenance_image from 'assets/images/maintenance.png';

const NotLivePage = ({name}) => {
    useEffect(() => {
        GoogleAnalytics.send('pageview')
    }, [])
    return (
        <>
            <Helmet>
                <title>503 Page Not Live</title>
                <meta name="description" content="Page not live" />
            </Helmet>
            <div className="h-100 d-flex pb-5">
                <div className="container h-100 d-flex flex-column justify-content-center text-center mt-5 position-relative" style={{maxHeight: "600px"}}>
                    <div className="mt-5" style={{flex: "0 1 auto", color: "#484848"}}><h1>Coming soon</h1></div>
                    <div className="mt-3" style={{flex: "1 1 auto"}}>
                        <img src={maintenance_image} width="100%" style={{maxHeight: "349px", maxWidth: "500px"}}/>
                    </div>
                    <div className="my-3" style={{flex: "0 1 auto"}}>
                        <p style={{color: "#777777"}}>
                            Contactless Ordering for <b>{name}</b> will be available soon.<br/>
                            We apologize for any inconvenience.
                        </p>
                    </div>
                    <div className="my-3" style={{flex: "0 1 auto"}}>
                        <Link to={document.referrer} className="btn btn-primary btn-lg px-5" style={{borderRadius: '100px', backgroundColor: "#0099DD", borderColor: "#0099DD"}}>
                            <span className="mx-5">Back</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotLivePage;