import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, weekdays } from "utils";
import { selectUser } from 'app/common/auth/selectors';
import { OperatingHours, WeeklyOperationHours } from "types";
import { Edit } from "@material-ui/icons";
import { merchantPageActions } from "../slice";
import Swal from "sweetalert2";

const OperationHoursModal = memo(() => {
    const dispatch = useDispatch();
    const user     = useSelector(selectUser).merchant;

    const [show, setShow]     = useState(false);
    const [saving, setSaving] = useState(false);
    
    useEffect(() => {
        setSaving(false);
        setShow(false);
    }, [user?.operation_hours])

    const [operationHoursSelect, setOperationHoursSelected] = useState<string | undefined>(
        user?.operation_hours?.everyday ? 'everyday':
        user?.operation_hours?.weekdays && user?.operation_hours?.weekends ? 'weekdays_weekends':
        user?.operation_hours?.weekdays ? 'weekdays':
        user?.operation_hours?.weekends ? 'weekends':
        (
            user?.operation_hours?.sunday ||
            user?.operation_hours?.monday ||
            user?.operation_hours?.tuesday ||
            user?.operation_hours?.wednesday ||
            user?.operation_hours?.thursday ||
            user?.operation_hours?.friday ||
            user?.operation_hours?.saturday
        ) ? 'custom' : undefined
    );
    const [operationHours, setOperationHours] = useState<WeeklyOperationHours | undefined>(user?.operation_hours);
    const [customOperationHoursSelected, setCustomOperationHoursSelected] = useState({
        sunday   : !!user?.operation_hours?.sunday,
        monday   : !!user?.operation_hours?.monday,
        tuesday  : !!user?.operation_hours?.tuesday,
        wednesday: !!user?.operation_hours?.wednesday,
        thursday : !!user?.operation_hours?.thursday,
        friday   : !!user?.operation_hours?.friday,
        saturday : !!user?.operation_hours?.saturday
    });

    const updateOperationHours = (select, hour, value) => {
        const hours = {...operationHours}
        hours[select] = !hours[select] ? {start: '', end: ''} : {...hours[select]}
        hours[select][hour] = value
        setOperationHours(hours)
    }

    const updateCustomOperationHoursSelected = weekday => {
        const customOpHours = {...customOperationHoursSelected}
        customOpHours[weekday] = !customOpHours[weekday]
        setCustomOperationHoursSelected(customOpHours);
    }

    const getCustomOperationHours = () => {
        const op_hours = {}
        for (const weekday of weekdays) {
            if (customOperationHoursSelected[weekday] && operationHours) {
                op_hours[weekday] = operationHours[weekday];
            }
        }
        return op_hours;
    }

    const get12HourTime = time => {
        const [hour, min] = time.split(':');
        const morning = parseInt(hour) < 12;
        return `${hour == 0 || hour == 12 ? 12 : morning ? hour : hour - 12}:${min} ${morning ? 'AM' : 'PM'}`
    }

    const timeRange = operationHours => (`
        from ${get12HourTime(operationHours.start)}
        -    ${get12HourTime(operationHours.end)}
    `)

    const saveOperationHours = () => {
        setSaving(true);
        const operation_hours = (
            operationHoursSelect == '24/7'              ? {}:
            operationHoursSelect == 'everyday'          ? {everyday: operationHours?.everyday}:
            operationHoursSelect == 'weekdays_weekends' ? {weekdays: operationHours?.weekdays, weekends: operationHours?.weekends}:
            operationHoursSelect == 'weekdays'          ? {weekdays: operationHours?.weekdays}:
            operationHoursSelect == 'weekends'          ? {weekends: operationHours?.weekends}:
            getCustomOperationHours()
        ) || {}
        for (const opHour of Object.values(operation_hours)) {
            if (
                !opHour ||
                !(opHour as OperatingHours).start ||
                !(opHour as OperatingHours).end
            ) {
                Swal.fire({
                    icon: 'error',
                    title: 'Incomplete Details',
                    text: 'Please fill up all time fields'
                })
                setSaving(false);
                return;
            }
        }
        dispatch(merchantPageActions.saveOperationHours({username: user?.username || "", operation_hours}));
    }

    useEffect(() => {
        setSaving(false);
    }, [user])

    return (<>
        <div className={`modal fade ${(show && 'show')} text-dark`} tabIndex={-1} aria-modal="true" role="dialog" onClick={e => setShow(false)}>
            <div className="modal-dialog modal-dialog-centered modal-lg" onClick={e => e.stopPropagation()}>
                <div className="modal-content">
                    <div className="modal-body">
                        <h4 className="modal-title text-center mb-3">
                            Operation Hours
                        </h4>
                        <div className="px-5">
                            <div className="form-group mb-4">
                                <label>Operation Hours</label>
                                <select className="form-control mb-3" onChange={e => setOperationHoursSelected(e.target.value)}>
                                    <option selected={!operationHoursSelect} value='24/7'>24/7</option>
                                    <option selected={operationHoursSelect == 'everyday'         } value='everyday'         >Everyday           </option>
                                    <option selected={operationHoursSelect == 'weekdays_weekends'} value='weekdays_weekends'>Weekdays & Weekends</option>
                                    <option selected={operationHoursSelect == 'weekdays'         } value='weekdays'         >Weekdays Only      </option>
                                    <option selected={operationHoursSelect == 'weekends'         } value='weekends'         >Weekends Only      </option>
                                    <option selected={operationHoursSelect == 'custom'           } value='custom'           >Custom             </option>
                                </select>
                                <div>
                                {
                                    (
                                        operationHoursSelect == 'everyday' ||
                                        operationHoursSelect == 'weekdays' ||
                                        operationHoursSelect == 'weekends'
                                    ) &&
                                    <div className="form-group mb-3">
                                        <label>{capitalize(operationHoursSelect)}</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">From</span>
                                            </div>
                                            <input type="time" className="form-control"
                                                max      = {operationHours ? operationHours[operationHoursSelect]?.end || "" : ""}
                                                value    = {operationHours ? operationHours[operationHoursSelect]?.start || "" : ""}
                                                onChange = {e => updateOperationHours(operationHoursSelect, 'start', e.target.value)}
                                            />
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">To</span>
                                            </div>
                                            <input type="time" className="form-control"
                                                min      = {operationHours ? operationHours[operationHoursSelect]?.start || "" : ""}
                                                value    = {operationHours ? operationHours[operationHoursSelect]?.end || "" : ""}
                                                onChange = {e => updateOperationHours(operationHoursSelect, 'end', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    operationHoursSelect == 'weekdays_weekends' && <>
                                        <div className="form-group mb-3">
                                            <label>Weekdays</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">From</span>
                                                </div>
                                                <input type="time" className="form-control"
                                                    max      = {operationHours ? operationHours.weekdays?.end || "" : ""}
                                                    value    = {operationHours ? operationHours.weekdays?.start || "" : ""}
                                                    onChange = {e => updateOperationHours('weekdays', 'start', e.target.value)}
                                                />
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">To</span>
                                                </div>
                                                <input type="time" className="form-control"
                                                    min      = {operationHours ? operationHours.weekdays?.start || "" : ""}
                                                    value    = {operationHours ? operationHours.weekdays?.end || "" : ""}
                                                    onChange = {e => updateOperationHours('weekdays', 'end', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label>Weekends</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">From</span>
                                                </div>
                                                <input type="time" className="form-control"
                                                    max      = {operationHours ? operationHours.weekends?.end || "" : ""}
                                                    value    = {operationHours ? operationHours.weekends?.start || "" : ""}
                                                    onChange = {e => updateOperationHours('weekends', 'start', e.target.value)}
                                                />
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">To</span>
                                                </div>
                                                <input type="time" className="form-control" 
                                                    min      = {operationHours ? operationHours.weekends?.start || "" : ""}
                                                    value    = {operationHours ? operationHours.weekends?.end || "" : ""}
                                                    onChange = {e => updateOperationHours('weekends', 'end', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                {
                                    operationHoursSelect == 'custom' &&
                                    weekdays.map(weekday =>
                                        <div className="form-group mb-3">
                                            <label>{capitalize(weekday)}</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text h-100">
                                                        <input type="checkbox"
                                                            checked  = {customOperationHoursSelected[weekday]}
                                                            onChange = {() => updateCustomOperationHoursSelected(weekday)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="input-group-append">
                                                    <span className="input-group-text">From</span>
                                                </div>
                                                <input type="time" className="form-control"
                                                    max      = {operationHours ? operationHours[weekday]?.end || "" : ""}
                                                    value    = {operationHours ? operationHours[weekday]?.start || "" : ""}
                                                    onChange = {e => updateOperationHours(weekday, 'start', e.target.value)}
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">To</span>
                                                </div>
                                                <input type="time" className="form-control"
                                                    min      = {operationHours ? operationHours[weekday]?.start || "" : ""}
                                                    value    = {operationHours ? operationHours[weekday]?.end || "" : ""}
                                                    onChange = {e => updateOperationHours(weekday, 'end', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                                </div>
                            </div>
                            <button className="btn btn-primary w-100 mb-2" onClick={saveOperationHours} disabled={saving}>{
                                !saving ? "SAVE" :
                                <div className="spinner-border text-light" role="status"></div>
                            }</button>
                            <button className="btn btn-link text-primary w-100  mb-2" onClick={e => setShow(false)} disabled={saving}>CANCEL</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {show && <div className={`modal-backdrop fade ${(show && 'show')}`} onClick={e => setShow(false)}></div>}
        <a href="#" rel="noopener noreferrer" onClick={e => setShow(true)} style={{textDecoration: 'none'}}>
            {<span style={{color: 'inherit'}}>
                {
                    (!user?.operation_hours || Object.keys(user.operation_hours).length == 0) && "Open 24/7"
                }
                {
                    user?.operation_hours?.everyday && <>
                    Monday - Sunday {timeRange(user?.operation_hours.everyday)}
                    </>
                }
                {
                    user?.operation_hours?.weekdays && <>
                    Monday - Friday {timeRange(user?.operation_hours.weekdays)}
                    </>
                }
                {
                    Object.keys(user?.operation_hours || {}).map(day => 
                    day != 'everyday' && day != 'weekdays' && day != 'weekends' &&
                    <>
                        {capitalize(day)} {timeRange(user?.operation_hours && user?.operation_hours[day])}
                        {day == Object.keys(user?.operation_hours || {})[Object.keys(user?.operation_hours || {}).length-1] ? "": <br/>}
                    </>)
                }
                {
                    user?.operation_hours?.weekends && <>
                    Saturday - Sunday {timeRange(user?.operation_hours.weekends)}
                    </>
                }
            </span>}
            <sup>
                <Edit style={{fontSize: '12px'}}/>
            </sup>
        </a>
    </>)
});

export default OperationHoursModal;