interface Props {
    customText?: string
    onConfirm: (e) => void
    onCancel: (e) => void
}

const ConfirmActionModal = (props: Props) => {
    return (<>
        <div className={`modal fade show`} id="modelId" role="dialog">
            <div className="modal-dialog modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        {props.customText ? props.customText : 'Are you sure?'}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" onClick={props.onCancel}>Cancel</button>
                        <button type="button" className="btn btn-primary" onClick={props.onConfirm}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
        <div className={`modal-backdrop fade show`}></div>
    </>)
}

export default ConfirmActionModal;