import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import GoogleAnalytics from 'app/common/ga';

import closed_image from 'assets/images/closed.svg';
import { capitalize } from 'utils';

const NotLivePage = ({merchant}) => {
    useEffect(() => {
        GoogleAnalytics.send('pageview')
    }, [])

    const get12HourTime = time => {
        const [hour, min] = time.split(':');
        const morning = parseInt(hour) < 12;
        return `${hour == 0 || hour == 12 ? 12 : morning ? hour : hour - 12}:${min} ${morning ? 'AM' : 'PM'}`
    }
    const timeRange = operationHours => (`
        from ${get12HourTime(operationHours.start)}
        -    ${get12HourTime(operationHours.end)}
    `)
    return (
        <>
            <Helmet>
                <title>{merchant.name}</title>
                <meta name="description" content="Merchant is closed" />
            </Helmet>
            <div className="h-100 d-flex pb-5">
                <div className="container h-100 d-flex flex-column justify-content-center text-center mt-5 position-relative py-4" style={{maxHeight: "600px"}}>
                    <div className="mt-5" style={{flex: "0 1 auto", color: "#484848"}}><h1>Sorry, We're Closed</h1></div>
                    <div className="mt-5" style={{flex: "1 1 auto"}}>
                        <img src={closed_image} width="100%" style={{maxHeight: "349px", maxWidth: "500px"}}/>
                    </div>
                    <div className="my-5" style={{flex: "0 1 auto"}}>
                        <p style={{color: "#777777"}}>
                            Contactless Ordering for <b>{merchant.name}</b> will be available {merchant.closed ? 'soon' : 'during:'}<br/>
                            {!merchant.closed && <>
                                {
                                    merchant.operation_hours.everyday && <>
                                    Monday - Sunday {timeRange(merchant.operation_hours.everyday)}<br/>
                                    </>
                                }
                                {
                                    merchant.operation_hours.weekdays && <>
                                    Monday - Friday {timeRange(merchant.operation_hours.weekdays)}<br/>
                                    </>
                                }
                                {
                                    Object.keys(merchant.operation_hours).map(day => 
                                    day != 'everyday' && day != 'weekdays' && day != 'weekends' &&
                                    <>
                                        {capitalize(day)} {timeRange(merchant.operation_hours[day])}<br/>
                                    </>)
                                }
                                {
                                    merchant.operation_hours.weekends && <>
                                    Saturday - Sunday {timeRange(merchant.operation_hours.weekends)}<br/>
                                    </>
                                }
                                
                            </>}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotLivePage;