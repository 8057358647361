// React, Redux libraries
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMerchantPage } from '../../selectors';

// Child Components
import EditModal from './EditGroupModal';
import Item from '../Item';
import AddModal from '../Item/AddItemModal';

import './styles.scss';

const Group = memo(({index: groupIndex}: {index: number}) => {
    const merchantPage = useSelector(selectMerchantPage);
    const [group, setGroup] = useState(merchantPage.groups ? merchantPage.groups[groupIndex] : undefined);
    const [loadingItems, setLoadingItems] = useState(true);

    useEffect(() => {
        if (group?.items) {
            setLoadingItems(false);
        }
    }, [group?.items])

    useEffect(() => {
        if (merchantPage.groups) {
            setGroup(merchantPage.groups[groupIndex])
        }
    }, [group, groupIndex, merchantPage])

    return (
        <div className="card shadow mb-3">
            <div className="card-header">
                <div>
                    <p className="title">{group?.name}</p>
                    <small
                        className = "text-muted"
                        dangerouslySetInnerHTML = {{ __html: (group?.description?.replaceAll('\n', '<br/>') || '')}}
                    />
                </div>
                <EditModal index={groupIndex} group={group} />
            </div>
            <div className="card-body">{
                    group && !loadingItems ? group.items?.map((item, index) => <>
                        <Item groupIndex={groupIndex} index={index} item={item} />
                    </>) :
                    <div className="spinner-border text-primary" role="status"></div>
                }
                <div>
                    <AddModal group={groupIndex} />
                </div>
            </div>
        </div>
    );
});

export default Group;