// React, Redux libraries
import { memo, useEffect, useState } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import {
    Box,
    Button,
    Card,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

// Component dependencies
import { CONTACTLESS_API_URL } from 'app/common/core_api/resources';
import { adminPageSaga } from './saga';
import { selectAdminPage } from './selectors';
import { adminPageActions, reducer, sliceKey } from './slice';

// Child Components
import AddModal from './components/Modals/AddUserModal';
import EditModal from './components/Modals/EditUserModal';

// Resources
import { selectAdmin } from 'app/common/auth/selectors';
import gocontactless_logo from 'assets/images/logo.png';
import UploadQRModal from './components/Modals/UploadQRModal';
import './styles.scss';

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '100%',
      overflowX: 'auto',
      overflowY: 'auto',
    },
    rowCell: {
      fontSize: 12,
    },
  }),
);

const AdminPage = memo(() => {
    const adminPage = useSelector(selectAdminPage);
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: adminPageSaga });
    const classes = useStyles();

    const dispatch = useDispatch();
    
    const admin = useSelector(selectAdmin);

    const [page, setPage] = useState<number>(0)
    const [order, setOrder] = useState<'asc' | 'desc'>("asc");
    const [orderBy, setOrderBy] = useState<number>(0);
    const [searchValue, setSearchValue] = useState<string>('');
    const [keyword, setKeyword] = useState<string>('');

    const uploadQrModalState            = useState(false);
    const [username, setUsername]       = useState<string>('');
    const [downloading, setDownloading] = useState<boolean>(false);
    const [generating, setGenerating]   = useState<boolean>(false);

    useEffect(() => {
        dispatch(adminPageActions.getUsers());
    }, [dispatch, adminPage.trigger])

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const reset = () => {
        dispatch(adminPageActions.getUsers());
    };

    const handleSearch = () => {
        dispatch(adminPageActions.searchUsers(keyword));
    };
    
    const subtitle = useState("Admin Dashboard");

    const deleteHandler = (data) => {
        Swal.fire({
            title: 'Are you sure you want to remove this user?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: `Yes`,
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(adminPageActions.deleteUser({id: data.id}))
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    };

    const generateQR = () => {
        if(!username){
            Swal.fire('Invalid username', '', 'warning')
        }else{
            //setDownloading(true)
            setGenerating(true)
            dispatch(adminPageActions.generate({username: username}));
        }
        
    }

    
    useEffect(() => {
        //setDownloading(false)
        setGenerating(false)
        setUsername('')
    }, [adminPage.qrCode])

    const [selectedValue, setSelectedValue] = useState<string>('merchant');
    const [downloadUsername, setDownloadUsername] = useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };

    const qrDownloadUrl = `${CONTACTLESS_API_URL}/admin/qr/${selectedValue}/${downloadUsername}`;

    const downloadQR = () => {
        if(!downloadUsername){
            Swal.fire('Invalid username', '', 'warning')
        }else{
            window.open(qrDownloadUrl, '_blank');
        }
        
    }

    return (
        !admin ? <CircularProgress size={20} />: <>
        <Helmet><title>Admin</title></Helmet>
        <div className="admin">
            <div className="side-bar">
                <header><img src={gocontactless_logo} alt="logo" /><br/><Typography variant="caption" style={{marginTop: "-20px"}}>ADMIN</Typography></header>
                <div className="main">
                    <Typography>Generate Merchant QR Code</Typography><p></p>
                    <TextField
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"
                        inputProps = {{min: 0, style: { textAlign: 'center' }}}
                        onChange = {e => setUsername(e.target.value)}
                        value = {username}
                        placeholder = "Username"
                    /><p></p>
                    <button style={{width: '100%'}} className="btn btn-primary" onClick={() => generateQR()}>
                        {
                            !generating ? "Generate" : <div className="spinner-border text-light" role="status"></div>
                        }
                    </button>
                    <Divider></Divider><br/>
                    <button style={{width: '100%'}} className="btn btn-primary" onClick={() => uploadQrModalState[1](true)}>
                        Upload Merchant QR
                    </button>
                    <Divider></Divider><br/><br/><br/>
                    <Typography>Download QR Code</Typography><p></p>
                    <FormControlLabel control={<Radio checked={selectedValue === "merchant"} onChange={handleChange} size="small" value="merchant" color="primary" />} label="Merchant" />
                    <FormControlLabel control={<Radio checked={selectedValue === "marketplace"} onChange={handleChange} size="small" value="marketplace" color="primary" />} label="Marketplace" />
                    <TextField
                        id="outlined-size-small"
                        variant="outlined"
                        size="small"
                        inputProps = {{min: 0, style: { textAlign: 'center' }}}
                        onChange = {e => setDownloadUsername(e.target.value)}
                        value = {downloadUsername}
                        placeholder = "Username"
                    /><p></p>
                    <button style={{width: '100%'}} className="btn btn-primary" onClick={() => downloadQR()}>
                        {
                            !downloading ? "Download" : <div className="spinner-border text-light" role="status"></div>
                        }
                    </button>
                </div>
            </div>
            <div className="content">
                <header>
                    <div>
                        <legend>GOCONTACTLESS</legend>
                        <h6>{subtitle}</h6>
                    </div>
                    <button className="btn-logout"><a href="/admin_logout">Logout</a></button>
                </header>
                <Divider></Divider>
                <div className="main">
                    {adminPage.usersLoading ? <></> : <AddModal />}
                    <UploadQRModal state={uploadQrModalState}/>
                    <Card style={{ padding: 10, width: '100%' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item>
                                        <div style={{ marginBottom: 15, marginRight: 7 }}>
                                            <Typography color="textPrimary" variant="h5"> Users</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid
                                    container
                                    alignItems="flex-start"
                                    justify="flex-end"
                                    direction="row"
                                >
                                    <Box component="span" alignItems="flex-start" pb={2}>
                                        <TextField
                                            variant="outlined"
                                            id="keyword"
                                            size="small"
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            onChange={event => {
                                                const { value } = event.target;
                                                setKeyword(value);
                                            }}
                                            value={keyword}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            startIcon={<SearchIcon size={16} />}
                                            onClick={() => handleSearch()}
                                            style={{ marginLeft: 10, marginRight: 5 }}
                                        >
                                            Search
                                        </Button>
                                        <Button variant="contained" 
                                            onClick={() => reset()}
                                        >
                                            Reset
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div style={{ marginTop: 10 }}>
                            <Paper className={classes.paper}>
                                {
                                    adminPage.usersLoading ? 
                                    <Grid container justify="center" alignItems="center" alignContent="center" style={{padding: 20}}>
                                        <Grid item>
                                            <CircularProgress color="primary" size={20}/>
                                        </Grid>
                                    </Grid> :
                                    <TableContainer style={{ width: 2700, height: 600, overflow: 'auto' }}>
                                        <Table size="small" aria-label="sticky table" stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Avatar</TableCell>
                                                    <TableCell>QR Code</TableCell>
                                                    <TableCell>User ID</TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Username</TableCell>
                                                    <TableCell>Email</TableCell>
                                                    <TableCell>Description</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    //adminPage.users!.slice(page*10, page*10+10).map(data =>
                                                    adminPage.users!.map(data =>
                                                        <TableRow>
                                                            {
                                                                Object.values(data)
                                                                    .slice(5, 6)
                                                                    .map((value: any) => <TableCell style={{width: "auto", whiteSpace: "nowrap", overflow: "hidden"}}><img src={value} alt="avatar" width="130px" height="70px"/></TableCell>)
                                                            }
                                                            {
                                                                Object.values(data)
                                                                    .slice(6, 7)
                                                                    .map((value: any) => <TableCell style={{width: "auto", whiteSpace: "nowrap", overflow: "hidden"}}><img src={value} alt="qr" width="70px" height="70px"/></TableCell>)
                                                            }
                                                            {
                                                                Object.values(data)
                                                                    .slice(0, 5)
                                                                    .map((value: any) => <TableCell style={{width: "auto", whiteSpace: "nowrap", overflow: "hidden"}}>{value}</TableCell>)
                                                            }
                                                            <TableCell>
                                                                <EditModal data={data}/>
                                                                <button
                                                                    className="btn-delete"
                                                                    onClick={() => deleteHandler(data)}
                                                                >
                                                                    Remove
                                                                </button>
                                                                
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Paper>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    </>)
});

export default AdminPage;