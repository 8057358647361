import { selectAdminPage } from 'app/pages/AdminPage/selectors';
import { adminPageActions } from 'app/pages/AdminPage/slice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import Swal from 'sweetalert2'

import 'styles/modal.scss'

export interface Props {
    data: any
}

const EditModal = (props: Props) => {
    const adminPage = useSelector(selectAdminPage);
    const [name, setName]                           = useState<string>('');
    const [username, setUsername]                   = useState<string>('');
    const [email, setEmail]                         = useState<string>('');
    const [password, setPassword]                   = useState<string>('');
    const [description, setDescription]             = useState<string>('');
    const [dispatcherContact, setDispatcherContact] = useState<string>('');
    const [image, setImage]                         = useState<string>('');
    const [qr_image, setQRImage]                    = useState<string>('');
    const [show, setShow]                           = useState(false);
    const [saving, setSaving]                       = useState(false);

    const {getRootProps, getInputProps} = useDropzone();
    const dispatch = useDispatch();

    useEffect(() => {
        setSaving(false);
        setName(props.data.name);
        setUsername(props.data.username);
        setEmail(props.data.email);
        setPassword('');
        setDescription(props.data.description);
        setDispatcherContact(props.data.dispatcher_contact);
        setImage(props.data.avatar_url);
        setQRImage(props.data.qr_url);
        setShow(false);
    }, [adminPage.trigger, dispatch])

    useEffect(() => {
        setSaving(false);
    }, [adminPage.validationError])

    const save = _ => {
        if(!name || !username || !email || !description || !image || !qr_image){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'All fields are required',
            })
        }else{
            setSaving(true);
            dispatch(adminPageActions.saveUser({
                user: {
                    id: props.data.id,
                    name: (name || ''),
                    username: (username || ''),
                    email: (email || ''),
                    password: (password || ''),
                    description: (description || ''),
                    dispatcher_contact: (dispatcherContact || ''),
                    avatar_url: (image || ''),
                    qr_url: (qr_image || '')
                }
            }))
        }
    }

    const getImageUri = e => {
        if (e.target.files) {
            const file = e.target.files[0];
            const name = file.name;
            const lastDot = name.lastIndexOf('.');
            const ext = name.substring(lastDot + 1);
            if (file.size > 1000000) {
                e.target.value = '';
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'File size exceeds limit, it should be not more than 1MB',
                })
                return;
            }
            if (!['jpg', 'jpeg', 'png'].includes(ext)) {
                e.target.value = '';
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'File type is not allowed',
                })
                return;
            }
            
            if (file) {
                var reader = new FileReader();
                reader.onload = function (data) {
                    setImage(String(data.target?.result));
                }
                reader.readAsDataURL(file);
            }
            else {
                console.log('File not found');
            }
        }
    }

    const getQRImageUri = e => {
        if (e.target.files[0]) {
            const file = e.target.files[0];
            const name = file.name;
            const lastDot = name.lastIndexOf('.');
            const ext = name.substring(lastDot + 1);
            if (file.size > 1000000) {
                e.target.value = '';
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'File size exceeds limit, it should be not more than 1MB',
                })
                return;
            }
            if (!['jpg', 'jpeg', 'png'].includes(ext)) {
                e.target.value = '';
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'File type is not allowed',
                })
                return;
            }
            
            if (file) {
                var reader = new FileReader();
                reader.onload = function (data) {
                    setQRImage(String(data.target?.result));
                }
                reader.readAsDataURL(file);
            }
            else {
                console.log('File not found');
            }
        }
    }

    const isNumber = (evt) => {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        if (key.length == 0) return;
        var regex = /^[ +)(0-9,\b-]+$/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    return (<>
    <div className={`modal fade ${(show && 'show')}`} tabIndex={-1} aria-modal="true" role="dialog" onClick={e => setShow(false)}>
        <div className="modal-dialog shadow" onClick={e => e.stopPropagation()}>
            <div className="modal-content">
                <div className="modal-body">
                    <h4 className="modal-title text-center mb-3">
                        Edit User
                    </h4>
                    <div className="px-5">
                        <div className="form-group mb-3">
                            <label htmlFor="name">Name *</label>
                            <input
                                type        = "text"
                                className   = "form-control"
                                maxLength   = {50}
                                value       = {name}
                                defaultValue= {props.data.name}
                                onChange    = {e => setName(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="username">Username *</label>
                            <input
                                type        = "text"
                                className   = "form-control"
                                maxLength   = {50}
                                value       = {username}
                                defaultValue= {props.data.username}
                                onChange    = {e => setUsername(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="email">Email *</label>
                            <input
                                type        = "text"
                                className   = "form-control"
                                maxLength   = {50}
                                value       = {email}
                                defaultValue= {props.data.email}
                                onChange    = {e => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="password">Password *</label>
                            <input
                                type        = "password"
                                className   = "form-control"
                                maxLength   = {50}
                                value       = {password}
                                onChange    = {e => setPassword(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="description">Description *</label>
                            <textarea
                                id          = "name"
                                className   = "form-control"
                                maxLength   = {150}
                                value       = {description}
                                defaultValue= {props.data.description}
                                onChange    = {e => setDescription(e.target.value)}>
                            </textarea>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="username">Dispatcher's Contact Number *</label>
                            <input
                                type        = "text"
                                className   = "form-control"
                                maxLength   = {12}
                                value       = {dispatcherContact}
                                onChange    = {e => setDispatcherContact(e.target.value)}
                                onKeyPress  = {e => isNumber(e)} />
                        </div>
                        {/*<div className="form-group mb-3">
                            <label htmlFor="qr_image">QR Image URL *</label>
                            <input
                                type        = "text"
                                className   = "form-control"
                                maxLength   = {150}
                                value       = {qr_image}
                                onChange    = {e => setQRImage(e.target.value)}>
                            </input>
                        </div>*/}
                        <div className="form-group mb-3">
                            <label htmlFor="image">Image *</label>
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} onChange={getImageUri} />
                                {!image ?
                                    <div className="text-center">
                                        <p>Click to upload</p>
                                        <small>jpg, jpeg, png (recommended 1080 x 600, max 1 mb)</small>
                                    </div> :
                                    <img src={image} alt={name} width="100%"/>
                                }
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="qr_image">QR Code *</label>
                            <div className="dropzone">
                                <input type="file" onChange={getQRImageUri} id="qr_image"/>
                                {!qr_image ?
                                    <div className="text-center">
                                        <small>jpg, jpeg, png (recommended 300 x 300, max 1 mb)</small>
                                    </div> :
                                    <img src={qr_image} alt="qr_code" width="100%"/>
                                }
                            </div>
                        </div>
                        <button className="btn btn-primary w-100 mb-2" onClick={save} disabled={saving}>{
                            !saving ? "SAVE" :
                            <div className="spinner-border text-light" role="status"></div>
                        }</button>
                        <button className="btn btn-link text-primary w-100  mb-2" onClick={e => setShow(false)} disabled={saving}>CANCEL</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {show && <div className={`modal-backdrop fade ${(show && 'show')}`} onClick={e => setShow(false)}></div>}
    <button className="btn-edit" onClick={e => setShow(true)}>
        Edit User
    </button>
    </>)
};

export default EditModal;