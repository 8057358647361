import { noImageUrl } from "app/pages/MerchantPage/components/Item";
import React, { memo } from "react";
import { AmountField } from "../Products/Item";
import { CartItem } from "../types";
import ShowMoreText from "react-show-more-text";

const classes = {
    item       : "col-sm-12 col-md-6 col-lg-4 mb-3 row",
    image      : "col-6 mb-3",
    details    : "col-6 no-wrap row g-0 ps-2",
    name       : "col-sm-6 fw-bold",
    price      : "col-sm-6 fw-bold",
    description: "col-12 text-muted",
    amount     : "col-12 fw-bold",
}

const Item = memo(({ item, quantity }: CartItem) => {
    return (
        <div className={classes.item}>
            <div className={classes.image}>
                <img src={item.image_url ? item.image_url : noImageUrl} width="100%" alt={item.name}/>
            </div>
            <div className={classes.details}>
                <div className="row" style={{height: 'max-content'}}>
                    <p className={classes.name}>{item.name}</p>
                    <small className={classes.price}>{item.price}</small>
                </div>
                <ShowMoreText
                    lines = {5}
                    more  = {<span><br/>Show more</span>}
                    less  = "Show less"
                    className = "item-description flex-fill row"
                >
                    <p dangerouslySetInnerHTML = {{ __html: item.description?.replaceAll('\n', '<br/>')}}/>
                </ShowMoreText>
                <AmountField {...item} />
            </div>
        </div>
    );
});

export default Item;