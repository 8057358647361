import { PayloadAction } from '@reduxjs/toolkit';
import { Group, Item, WeeklyOperationHours } from 'types';
import { CoreApiErrorType } from 'types/CoreApiErrorType';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';


// The initial state of the ReportsPage container
export const initialState: ContainerState = {
    groupsLoading: true,
    apiError: undefined,
    showAddModal: false,
    showEditModal: false,
    generating: true,
    qrCode: '',
};

const merchantPageSlice = createSlice({
    name: 'merchantPage',
    initialState,
    reducers: {
        getGroups(state, action: PayloadAction<number | undefined>) {
            state.merchant_id = action.payload;
            state.groupsLoading = true;
        },
        groupsLoaded(state, action: PayloadAction<Array<Group>>) {
            state.groups = action.payload;
            state.groupsLoading = false;
        },
        itemsLoaded(state, action: PayloadAction<{
            group_index: number,
            items: Array<Item>
        }>) {
            const data = action.payload
            if (state.groups) {
                state.groups[data.group_index].items = data.items
            }
        },
        setAvailable(state, action: PayloadAction<{
            groupIndex: number,
            index: number,
            item: Item
        }>) {},
        availableUpdated(state, action: PayloadAction<{
            groupIndex: number,
            index: number,
            available: boolean
        }>) {
            const data = action.payload
            if (state.groups) {
                const items = state.groups[data.groupIndex].items
                if (items) {
                    items[data.index].available = data.available ? 'yes' : 'no'
                }
            }
        },
        generate(state, action: PayloadAction<{id: number|undefined}>) {
            state.generating = true;
        },
        generated(state, action: PayloadAction<any>) {
            state.qrCode = action.payload;
            state.generating = false;
        },
        saveGroup(state, action: PayloadAction<{
            index?: number,
            group?: Group
        }>) {},
        savedGroup(state, action: PayloadAction<{
            index?: number,
            group?: Group
        }>) {
            const {index, group} = action.payload
            if (state.groups && group) {
                const groups = [...state.groups]
                if (index !== undefined) {
                    const items = groups[index].items;
                    groups[index] = group;
                    groups[index].items = items;
                }
                else {
                    group.items = [];
                    groups.push(group)
                }
                state.groups = groups;
            }
        },
        deleteGroup(state, action: PayloadAction<{index: number, id: number}>) {},
        deletedGroup(state, action: PayloadAction<{index: number, id: number}>) {
            const {index} = action.payload;
            if (state.groups) {
                state.groups.splice(index, 1);
            }
        },
        saveItem(state, action: PayloadAction<{
            groupIndex: number,
            index?: number,
            item: Item
        }>) {},
        savedItem(state, action: PayloadAction<{
            groupIndex: number,
            index?: number,
            item: Item
        }>) {
            const {groupIndex, index, item} = action.payload
            if (state.groups && state.groups[groupIndex]) {
                const group = state.groups[groupIndex];
                if (group.items) {
                    if (index !== undefined) {
                        group.items[index] = item;
                    } else {
                        group.items.push(item)
                    }
                }
            }
        },
        deleteItem(_, action: PayloadAction<{groupIndex: number, index: number, id: number}>) {},
        deletedItem(state, action: PayloadAction<{groupIndex: number, index: number}>) {
            const {groupIndex, index} = action.payload
            if (state.groups && state.groups[groupIndex]) {
                state.groups[groupIndex].items?.splice(index, 1);
            }
        },
        saveOperationHours(state, action: PayloadAction<{username: string, operation_hours: WeeklyOperationHours}>) {},
        apiError(state, action: PayloadAction<CoreApiErrorType>) {
            state.groupsLoading = false;
            state.apiError = action.payload;
        },
    },
});

export const {
    actions: merchantPageActions,
    reducer,
    name: sliceKey,
} = merchantPageSlice;
